export const switchContainsOrIn = (tags, intersect = false) => {
  if (typeof tags === 'string') {
    return {
      contains: tags,
    }
  }

  if (Array.isArray(tags) && tags.length) {
    const filtered = tags.map((item) => item.slug)

    if (filtered.length === 1) {
      return {
        contains: filtered[0],
      }
    }

    if (!intersect) {
      return { in: filtered }
    }

    // Intersect -> use it if you want to filter a array of filter on a array of tags (AND)
    return filtered.map((item) => ({ contains: item }))
  }

  // if its a single object
  if (tags?.slug) {
    return {
      contains: tags.slug,
    }
  }

  return {}
}
